import Sortable from 'stimulus-sortable'

function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element.getAttribute("content")
}

export default class extends Sortable {
  connect() {
    super.connect()

    // The sortable.js instance.
    this.sortable

    // Your options
    this.options

    // Your default options
    this.defaultOptions
  }

  // You can set default options in this getter for all sortable elements.
  get defaultOptions() {
    return {
      group: {
        name: 'draggable-items',
        pull: true
      },
      animation: 0,
      onAdd: function(evt){
        console.info(JSON.stringify( { date: evt.to.dataset.listDate } ))
        fetch('/item/date/' + evt.item.dataset.itemId, {
          body: JSON.stringify( { date: evt.to.dataset.listDate } ),
          method: 'POST',
          dataType: 'script',
          credentials: "include",
          headers: {
            "Accept": 'text/vnd.turbo-stream.html',
            "Content-Type": 'application/json',
            "X-CSRF-Token": getMetaValue("csrf-token")
           },
        }).then(r => r.text())
          .then(html => Turbo.renderStreamMessage(html))
      }
    }
  }
}


